<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="itemCategoryEditDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ editItemCategoryTitle }}
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="editItemCategory.name"
                  label="Name"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="editItemCategory.is_directory"
                  :disabled="editItemCategoryId>0"
                  :items="isDirectoryList"
                  item-text="name"
                  item-value="value"
                  label="Is Directory"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="editItemCategory.parent_id"
                  :disabled="parentIdEditControl"
                  :items="itemCategory"
                  item-text="name"
                  item-value="id"
                  label="Parent for Category"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="resetEditIemCategory()"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="editItemCategoryId>-1"
            text
            @click="updateItemCategory()"
          >
            Update
          </v-btn>
          <v-btn
            v-else
            text
            @click="storeItemCategory()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDeleteSelectedDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          Warring, please read warring information first !!
        </v-card-title>
        <v-card-text>
          Are you sure you want delete Item Category <b>"{{ editItemCategory.name }}"</b> ? If YES please typ <b>"{{ editItemCategory.name }}"</b>
          <ul>
            <li>If you deleted parent category, all sub category will also delete</li>
            <li>All item belong to this Item Category will be set to null for category information</li>
            <li>All item type belong to this Item Category will be set to null for category information</li>
          </ul>
          <v-text-field
            v-model="confirmDeletedName"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="resetDeleteConfirm()"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="red"
            @click="deleteSelectItemCategory()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <material-card
      class="ml-5 mr-5"
      icon="mdi-clipboard-text"
      icon-small
      color="accent"
      title="Item Category Management"
    >
      <snack-bar ref="snackBar" />
      <v-card-actions>
        <v-row class="mx-2">
          <v-spacer />
          <v-col
            cols="7"
            md="3"
          >
            <v-text-field
              v-model="ItemCategorySearch"
              placeholder="Search Item Category"
            />
          </v-col>
          <v-col
            cols="5"
            md="2"
          >
            <v-btn
              fab
              small
              class="mt-2"
              @click="loadItemCategoryList(ItemCategorySearch,1)"
            >
              <v-icon dark>
                mdi-magnify
              </v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="mt-2 ml-3"
              color="primary"
              @click="itemCategoryEditDialog=true"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-data-table
        :headers="itemCategoryHeader"
        :items="itemCategory"
        items-per-page="15"
        dense
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editSelectItemCategory(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="confirmDeleteSelected(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-pagination
        v-model="itemCategoryMeta.current_page"
        :length="itemCategoryMeta.last_page"
        @input="changePageTo"
      ></v-pagination>
    </material-card>
  </v-container>
</template>

<script>
  import ItemCategoryApi from '@/api/item-category'
  import SnackBar from '@/components/app/SnackBar'
  import { get, sync } from 'vuex-pathify'
  import store from '@/store'

  export default {
    name: 'ItemCategory',
    components: { SnackBar },
    data () {
      return {
        itemCategoryHeader: [
          { text: '#', align: 'start', value: 'id' },
          { text: 'name', value: 'name' },
          { text: 'Is Directory', value: 'is_directory' },
          { text: 'Parent', value: 'parent_category.name' },
          { text: 'Path', value: 'full_name' },
          { text: '', value: 'actions', sortable: false },
        ],
        ItemCategorySearch: '',
        itemCategoryEditDialog: false,
        editItemCategoryId: -1,
        isDirectoryList: [{ name: 'Yes', value: true }, { name: 'No', value: false }],
        defaultItemCategory: {
          name: '',
          is_directory: true,
          path: '',
          level: '',
          parent_id: null,
          parent_category: '',
          full_name: '',
        },
        editItemCategory: {
          name: '',
          is_directory: true,
          path: '',
          level: '',
          parent_id: null,
          parent_category: '',
          full_name: '',
        },
        confirmDeleteSelectedDialog: false,
        confirmDeletedName: '',
      }
    },
    computed: {
      ...get('category', [
        'itemCategory',
        'itemCategoryMeta',
        'itemCategoryLoad',
      ]),
      editItemCategoryTitle () {
        return this.editItemCategoryId === -1 ? 'New Item Category' : 'Edit Item Category'
      },
      parentIdEditControl () {
        if (this.editItemCategoryId > -1) {
          return true
        }
        return this.editItemCategory.is_directory
      },
    },
    created () {
      this.loadItemCategoryList('all', 1)
    },
    methods: {
      loadItemCategoryList (categoryName, pageNumber) {
        if (!categoryName || categoryName === '') categoryName = 'all'
        store.set('category/getItemCategory', { categoryName: categoryName, pageNumber: pageNumber })
      },
      loadItemCategory (id) {
        ItemCategoryApi.show(id).then(response => {
          if (response.data.code !== 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          } else {
            this.editItemCategory = Object.assign({}, response.data.data)
            this.itemCategoryEditDialog = true
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      editSelectItemCategory (item) {
        this.editItemCategoryId = item.id
        this.loadItemCategory(item.id)
      },
      storeItemCategory () {
        ItemCategoryApi.store(this.editItemCategory).then(response => {
          if (response.data.code !== 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
          this.resetEditIemCategory()
          this.loadItemCategoryList()
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      updateItemCategory () {
        ItemCategoryApi.update(this.editItemCategory, this.editItemCategoryId).then(response => {
          if (response.data.code !== 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
          this.resetEditIemCategory()
          this.loadItemCategoryList()
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      confirmDeleteSelected (item) {
        this.editItemCategory = Object.assign({}, item)
        this.confirmDeleteSelectedDialog = true
      },
      resetDeleteConfirm () {
        this.confirmDeleteSelectedDialog = false
        this.confirmDeletedName = ''
        this.editItemCategory = Object.assign({}, this.defaultItemCategory)
      },
      deleteSelectItemCategory (item) {
        if (this.confirmDeletedName !== this.editItemCategory.name) {
          this.resetDeleteConfirm()
          store.set('message/storeMessage', {
            color: 'error',
            text: 'You have entered incorrect verification information, please check again',
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        } else {
          ItemCategoryApi.delete(this.editItemCategory.id).then(response => {
            if (response.data.code !== 200) {
              store.set('message/storeMessage', {
                color: response.data.type,
                text: response.data.message,
                timeout: 3000,
              })
              this.$refs.snackBar.activeStatusInfo()
            }
          }).catch(error => {
            store.set('message/storeMessage', {
              color: 'error',
              text: error,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }).finally(() => {
            this.resetDeleteConfirm()
            this.loadItemCategoryList()
          })
        }
      },
      resetEditIemCategory () {
        this.itemCategoryEditDialog = false
        this.editItemCategoryId = -1
        this.$nextTick(() => {
          this.editItemCategory = Object.assign({}, this.defaultItemCategory)
        })
      },
      changePageTo (page) {
        this.loadItemCategoryList(this.ItemCategorySearch, page)
      },
    },
  }
</script>

<style scoped>

</style>
